<template>
  <div if="!loading">
    <ul
      v-for="global in globals"
      :key="global.depositAmount"
    >
      <div class="w-full flex flex-col items-center px-12 pt-12 pb-8 bg-gray-200">
        <!-- Pending Button -->
        <button
          :to="{ name: 'home' }"
          style="max-width: 220px"
          class="flex flex-row p-2 text-white uppercase tracking-wide bg-secondary rounded centerContainer"
          v-if="unit.status ==='pending' && isReserver"
          @click="cancelReserve()"
        >
          <BackButtonIcon class="mr-1" />
          Back To Price List
        </button>
        <!-- elseButton -->
        <button
          v-else
          :to="{ name: 'home' }"
          style="max-width: 220px"
          class="flex flex-row p-2 text-white uppercase tracking-wide bg-secondary rounded centerContainer"
          @click="backToPriceList()"
        >
          <BackButtonIcon class="mr-1" />
          Back To Price List
        </button>

        <h2 class="mt-5 text-center text-xl font-semibold">Reservation Agreement Form</h2>

        <p class="mt-5 text-center font-semibold">
          To secure your reservation we require {{global.depositAmount | prettyCurrency}} non-refundable
          deposit. Only once this has been paid will your unit be reserved. This
          unit will be reserved for 24 hours from the time the reservation deposit
          was paid, in order to allow you to get the full agreement of the sale
          in. This deposit will be deducted from the purchase deposit amount.
        </p>
      </div>

      <div class="flex flex-col items-center p-5 bg-white">
        <!-- Form -->
        <div class="flex flex-col justify-center items-center mx-auto">
          <h1 class="text-lg font-semibold">
            {{ title }} <span class="font-normal">Unit {{ unit.name }}</span>
          </h1>

          <div class="mt-2" v-if="unitForm.unitParkingBay">
            <span class="block font-extrabold text-md text-primary">
              {{ purchasePriceTotal().price | prettyCurrency }}
            </span>
          </div>
          <div class="font-extrabold text-md mt-2" v-else>
            <UnitPrice :bigPrice="false" :unit="unit" :settings="settings" :show="false"/>
          </div>

          <!-- form wrapper -->
          <div class="modalContainer">
            <!-- User form -->
            <div
              v-bind:class="{
              hidden: openTab !== 1,
              block: openTab === 1,
            }"
            >
              <div v-if="settings.allowReserve || specialBypass">
                <form @submit.prevent="userFormSubmit">
                  <div class="formInput">
                    <div class="formInputField">
                      <label
                        for="register-firstname"
                        class="text-xs font-semibold px-1"
                      >First name</label
                      >
                      <div class="flex">
                        <div
                          class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"
                        >
                          <NameIcon />
                        </div>
                        <input
                          v-model="clientForm.firstName"
                          type="text"
                          class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-1 border-gray-400 outline-none focus:border-primary focus:ring-primary"
                          placeholder=""
                        />
                      </div>
                      <span
                        class="text-xs text-red-600"
                        :class="{
                        '': $v.clientForm.firstName.$error,
                        invisible: !$v.clientForm.firstName.$error,
                      }"
                      >
                      Required</span
                      >
                    </div>

                    <div class="formInputField">
                      <label
                        for="register-lastname"
                        class="text-xs font-semibold px-1"
                      >Last name</label
                      >
                      <div class="flex">
                        <div
                          class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"
                        >
                          <NameIcon />
                        </div>
                        <input
                          v-model="clientForm.lastName"
                          type="text"
                          class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-1 border-gray-400 outline-none focus:border-primary focus:ring-primary"
                          placeholder=""
                        />
                      </div>
                      <span
                        class="text-xs text-red-600"
                        :class="{
                        '': $v.clientForm.lastName.$error,
                        invisible: !$v.clientForm.lastName.$error,
                      }"
                      >
                      Required</span
                      >
                    </div>
                  </div>

                  <div class="flex -mx-3">
                    <div class="w-full px-3">
                      <label
                        for="register-email"
                        class="text-xs font-semibold px-1"
                      >Email</label
                      >
                      <div class="flex">
                        <div
                          class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"
                        >
                          <EmailIcon />
                        </div>
                        <input
                          v-model="clientForm.email"
                          type="email"
                          class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-1 border-gray-400 outline-none focus:border-primary focus:ring-primary"
                          placeholder=""
                        />
                      </div>
                      <span
                        class="text-xs text-red-600"
                        :class="{
                        '': $v.clientForm.email.$error,
                        invisible: !$v.clientForm.email.$error,
                      }"
                      >
                      Required</span
                      >
                    </div>
                  </div>

                  <div class="flex -mx-3">
                    <div class="w-full px-3">
                      <label for="" class="text-xs font-semibold px-1"
                      >Contact Number</label
                      >
                      <div class="flex">
                        <VuePhoneNumberInput
                          v-model="clientForm.contact"
                          @update="updatePhoneNumber"
                          class="w-full"
                          size="md"
                          color="#aa9461"
                          default-country-code="ZA"
                        />
                      </div>
                      <span
                        class="text-xs text-red-600"
                        :class="{
                        '': $v.clientForm.contact.$error,
                        invisible: !$v.clientForm.contact.$error,
                      }"
                      >
                      Enter valid contact number</span
                      >
                    </div>
                  </div>

                  <div class="flex flex-col -mx-3 mt-2">
                    <div class="w-full flex flex-row px-3 mb-0">
                      <input
                        v-model="clientForm.tac"
                        id="register-tac"
                        type="checkbox"
                        class="mr-2 rounded text-primary"
                      />
                      <p class="lb-icon-text-label">
                        Accept
                        <a target="_blank" :href="tacLink" class="underline"
                        >Terms and Conditions</a
                        >
                      </p>
                    </div>
                    <div class="mt-2 ml-3">
                    <span
                      class="block w-full text-xs text-red-600"
                      :class="{
                        '': $v.clientForm.tac.$error,
                        invisible: !$v.clientForm.tac.$error,
                      }"
                    >
                      Accept our Terms &amp; Conditions to proceed</span
                    >
                    </div>
                  </div>

                  <!-- <div class="mt-4">
                      <button class="lb-submit-btn" type="submit">Next: Deposit Payment</button>
                      <p class="mt-2 text-sm">*Please note you will have 10 minutes to make payment before your reservation slot expires</p>
                    </div> -->
                  <div
                    class="w-2/4 mt-5"
                    v-if="settings.allowReserve || specialBypass"
                  >
                    <!--                    <button-->
                    <!--                      disabled-->
                    <!--                      class="bg-gray-500 w-full items-center px-4 py-2 text-black text-md font-semibold uppercase tracking-wider cursor-not-allowed rounded"-->
                    <!--                      v-if="unit.status === 'pending'"-->
                    <!--                    >-->
                    <!--                      Pending-->
                    <!--                    </button>-->

                    <button
                      disabled
                      class="bg-gray-500 w-full items-center px-4 py-2 text-black text-md font-semibold uppercase tracking-wider cursor-not-allowed rounded"
                      v-if="unit.status === 'reserved'"
                    >
                      Reserved
                    </button>

                    <button
                      disabled
                      class="bg-gray-500 w-full items-center px-4 py-2 text-black text-md font-semibold uppercase tracking-wider cursor-not-allowed rounded"
                      v-if="unit.status === 'sold'"
                    >
                      Sold
                    </button>

                    <button
                      class="bg-primary items-center w-full px-4 py-2 text-black text-md font-semibold uppercase tracking-wider duration-150 rounded"
                      type="submit"
                      v-if="unit.status === 'available' && !disableReserve && !unit.preReserved"
                      v-bind="{isLoading, status}"
                    >
                      <ButtonSpinner
                        style="background-color:#AA9461;
                                color:black;
                                font-size:18px;
                                border:none;
                                padding: 0;
                                height: min-content;
                                width: 100%;
                                text-transform: uppercase;
                                font-weight: 600;
                                letter-spacing: 1.5px"
                        @click.native="onClick"
                        @click.prevent="userFormSubmit"
                        v-bind="{isLoading, status}"
                        :disabled="isLoading"
                        type="submit"
                      >
                        Continue
                      </ButtonSpinner>
                    </button>
                    <!-- pre-reserved disable -->
                    <button
                      disabled
                      class="bg-gray-400 items-center w-full px-4 py-2 text-white text-md font-semibold tracking-wider duration-150 uppercase hover:bg-gray-500 rounded cursor-not-allowed"
                      type="submit"
                      v-if="unit.status === 'available' && !disableReserve && unit.preReserved"
                    >
                      Pending
                    </button>
                    <!-- normal disable -->
                    <button
                      disabled
                      class="bg-gray-400 items-center w-full px-4 py-2 text-white text-md font-semibold tracking-wider duration-150 uppercase hover:bg-gray-500 rounded cursor-not-allowed"
                      v-if="unit.status === 'available' && disableReserve"
                      @click="setTooltip()"
                    >
                      <span class="text-md font-semibold uppercase">
                        Continue
                      </span>
                    </button>
                  </div>

                  <div v-if="allowBypass" class="mt-4">
                    <button
                      @click.prevent="agentBypass"
                      class="lb-submit-btn"
                      :class="{
                      'bg-gray-500':
                        unit.status === 'pending' ||
                        unit.status === 'reserved' ||
                        unit.status === 'sold',
                      'bg-red-500': unit.status === 'available',
                    }"
                      :disabled="
                      unit.status === 'pending' ||
                      unit.status === 'reserved' ||
                      unit.status === 'sold'
                    "
                    >
                      Agent Bypass
                    </button>
                  </div>

                  <p v-if="unit.status === 'available'  && !disableReserve" class="mt-3 text-sm">
                    *Please note you will have <b>{{ formattedTimeLeft }}</b> minutes to complete your reservation.
                  </p>
                </form>
              </div>

              <div v-else>
                <div class="flex flex-col items-center justify-center">
                  <HomeIcon :size="50" />
                  <h2 class="mt-2 text-lg font-semibold">
                    We are currently not accepting online reservations
                  </h2>
                  <p class="mt-2">
                    You may contact our agents directly for more information.
                  </p>
                  <button
                    @click.prevent="openContactDialog()"
                    class="flex flex-row items-center justify-center mt-2 px-6 py-2 font-bold tracking-wider uppercase text-sm rounded outline-none text-white bg-secondary hover:bg-secondarydark border border-solid border-seondary hover:text-white focus:outline-none"
                  >
                    <ContactIcon :size="23" class="mr-1" />
                    Contact
                  </button>
                </div>
              </div>
            </div>

            <!-- Unit Details form -->
            <div
              class="text-center"
              v-bind:class="{
              hidden: openTab !== 2,
              block: openTab === 2,
            }"
            >
              <div
                class="flex flex-row flex-wrap items-center justify-center w-full mt-10"
              >
                <div class="flex flex-col items-center mb-3 px-5" v-if="unit.bedrooms">
                  <BedroomIcon />
                  <span class="font-semibold">Bedrooms</span>
                  <span class="font-semibold">{{ unit.bedrooms }}</span>
                </div>

                <div class="flex flex-col items-center mb-3 px-5" v-if="unit.bathrooms">
                  <BathroomIcon />
                  <span class="font-semibold">Bathrooms</span>
                  <span class="font-semibold">{{ unit.bathrooms }}</span>
                </div>

                <div class="flex flex-col items-center mb-3 px-5" v-if="unit.parking">
                  <CarIcon />
                  <span class="font-semibold">Parking Spots</span>
                  <span class="font-semibold">{{ unit.parking }}</span>
                </div>

                <div class="flex flex-col items-center mb-3 px-5" v-if="unit.isAircon">
                  <SnowflakeIcon />
                  <span class="font-semibold">Aircon</span>
                  <span class="font-semibold">Yes</span>
                </div>

                <div
                  class="flex flex-col items-center mb-3 px-5"
                  v-if="unit.externalArea"
                >
                  <IntAreaIcon />
                  <span class="font-semibold">Internal Area</span>
                  <span class="font-semibold">{{ unit.internalArea }}m&sup2;</span>
                </div>

                <div
                  class="flex flex-col items-center mb-3 px-5"
                  v-if="unit.externalArea"
                >
                  <ExtAreaIcon />
                  <span class="font-semibold">External Area</span>
                  <span class="font-semibold">{{ unit.externalArea }}m&sup2;</span>
                </div>

                <div class="flex flex-col items-center mb-3 px-5" v-if="unit.bedrooms">
                  <TotalAreaIcon />
                  <span class="font-semibold">Total Area</span>
                  <span class="font-semibold">{{ unit.totalArea }}m&sup2;</span>
                </div>
              </div>

              <form>
                <div class="w-full flex justify-center mt-5 border-t">
                  <h2 class="block w-full mt-5 font-bold text-lg">
                    Additional Extras
                  </h2>
                </div>

                <div class="additionalExtras">
                  <div class="w-full flexDir lg:w-1/2">
                    <div v-if="extraSettings.isParkingOne && extraSettings.isParkingTandem && !parkingStatuses.includes(unit.type)">
                      <label
                        v-if="(extraSettings.parkingOneAmounts === null || extraSettings.parkingOneAmounts === '0') && extraSettings.parkingTandemAmounts > 0"
                        class="flex justify-center align-center w-full"
                      >
                        <b class="pr-2">Parking </b> {{parseFloat(0) + parseFloat(extraSettings.parkingTandemAmounts)}}/{{extraSettings.parkingTotalBays}} Available
                      </label>
                      <label
                        v-if="(extraSettings.parkingTandemAmounts === null || extraSettings.parkingTandemAmounts === '0') && extraSettings.parkingOneAmounts > 0"
                        class="flex justify-center align-center w-full"
                      >
                        <b class="pr-2">Parking </b> {{parseFloat(extraSettings.parkingOneAmounts) + parseFloat(0)}}/{{extraSettings.parkingTotalBays}} Available
                      </label>
                      <label
                        v-if="(extraSettings.parkingOneAmounts === null || extraSettings.parkingOneAmounts === '0') && (extraSettings.parkingTandemAmounts === null || extraSettings.parkingTandemAmounts === '0')"
                        class="flex justify-center align-center w-full"
                      >
                        <b class="pr-2">Parking </b> {{parseFloat(0) + parseFloat(0)}}/{{extraSettings.parkingTotalBays}} Available
                      </label>
                      <label
                        v-if="extraSettings.parkingOneAmounts > 0 && extraSettings.parkingTandemAmounts > 0"
                        class="flex justify-center align-center w-full"
                      >
                        <b class="pr-2">Parking </b> {{parseFloat(extraSettings.parkingOneAmounts) + parseFloat(extraSettings.parkingTandemAmounts)}}/{{extraSettings.parkingTotalBays}} Available
                      </label>
                    </div>
                    <div v-else-if="extraSettings.isParkingOne">
                      <label
                        v-if="(extraSettings.parkingOneAmounts === null || extraSettings.parkingOneAmounts === '0')"
                        class="flex justify-center align-center w-full"
                      >
                        <b class="pr-2">Parking </b> {{parseFloat(0)}} /{{extraSettings.parkingTotalBays}} Available
                      </label>
                      <label
                        v-if="extraSettings.parkingOneAmounts > 0"
                        class="flex justify-center align-center w-full"
                      >
                        <b class="pr-2">Parking </b> {{parseFloat(extraSettings.parkingOneAmounts)}}/{{extraSettings.parkingTotalBays}} Available
                      </label>
                    </div>
                    <div v-else-if="extraSettings.isParkingTandem && !parkingStatuses.includes(unit.type)">
                      <label
                        v-if="(extraSettings.parkingTandemAmounts === null || extraSettings.parkingTandemAmounts === '0')"
                        class="flex justify-center align-center w-full"
                      >
                        <b class="pr-2">Parking </b> {{parseFloat(0)}} /{{extraSettings.parkingTotalBays}} Available
                      </label>
                      <label
                        v-if="extraSettings.parkingTandemAmounts > 0"
                        class="flex justify-center align-center w-full"
                      >
                        <b class="pr-2">Parking </b> {{parseFloat(extraSettings.parkingTandemAmounts)}}/{{extraSettings.parkingTotalBays}} Available
                      </label>
                    </div>
                    <div v-else>
                      <label
                        v-if="(extraSettings.parkingOneAmounts === null || extraSettings.parkingOneAmounts === '0')"
                        class="flex justify-center align-center w-full"
                      >
                        <b class="pr-2">Parking </b> {{parseFloat(0)}} /{{extraSettings.parkingTotalBays}} Available
                      </label>
                      <label
                        v-if="extraSettings.parkingOneAmounts > 0"
                        class="flex justify-center align-center w-full"
                      >
                        <b class="pr-2">Parking </b> {{parseFloat(extraSettings.parkingOneAmounts)}}/{{extraSettings.parkingTotalBays}} Available
                      </label>
                    </div>
                    <select
                      v-model="unitForm.unitParkingBay"
                      class="lb-admin-form-input-select mt-2 rounded"
                    >
                      <option value="0">No Thanks</option>
                      <option
                        v-if="extraSettings.isParkingOne && extraSettings.parkingOneAmounts > 0"
                        v-bind:value="extraSettings.parkingOnePrice"
                      >
                        {{ extraSettings.parkingOneName }} Bay - {{ extraSettings.parkingOnePrice | prettyCurrency }}
                      </option>
                      <option
                        v-if="extraSettings.isParkingOne && extraSettings.parkingOneAmounts === null"
                        v-bind:value="extraSettings.parkingOnePrice"
                        disabled
                      >
                        {{ extraSettings.parkingOneName }} Bay - {{ extraSettings.parkingOnePrice | prettyCurrency }}
                      </option>
                      <option
                        v-if="extraSettings.isParkingTandem && extraSettings.parkingTandemAmounts > 0 && !parkingStatuses.includes(unit.type)"
                        v-bind:value="extraSettings.parkingTandemPrice"
                      >
                        {{ extraSettings.parkingTandemName }} Bay - {{ extraSettings.parkingTandemPrice | prettyCurrency }}
                      </option>
                      <option
                        v-if="extraSettings.isParkingTandem && extraSettings.parkingTandemAmounts === null && !parkingStatuses.includes(unit.type)"
                        v-bind:value="extraSettings.parkingTandemPrice"
                        disabled
                      >
                        {{ extraSettings.parkingTandemName }} Bay - {{ extraSettings.parkingTandemPrice | prettyCurrency }}
                      </option>
                      <option
                        v-if="extraSettings.isParkingTwo && extraSettings.parkingTwoAmounts > 0"
                        v-bind:value="extraSettings.parkingTwoPrice"
                      >
                        {{ extraSettings.parkingTwoName }} Bay - {{ extraSettings.parkingTwoPrice | prettyCurrency }}
                      </option>
                      <option
                        v-if="extraSettings.isParkingTwo && extraSettings.parkingTwoAmounts === null"
                        v-bind:value="extraSettings.parkingTwoPrice"
                        disabled
                      >
                        {{ extraSettings.parkingTwoName }} Bay - {{ extraSettings.parkingTwoPrice | prettyCurrency }}
                      </option>
                    </select>
                    <span
                      class="text-xs text-red-600"
                      :class="{
                        '': $v.unitForm.unitParkingBay.$error,
                        invisible: !$v.unitForm.unitParkingBay.$error,
                      }"
                    >
                      Required</span
                    >
                  </div>

                  <div
                    v-if="extraSettings.isGuaranteedRental"
                    class="w-full flexDir lg:w-1/2"
                  >
                    <label class="flexDir w-full">{{extraSettings.guaranteedRentalName }} Guaranteed Rental</label>
                    <select
                      v-model="unitForm.unitGuaranteedRentalPrice"
                      class="lb-admin-form-input-select mt-2 rounded"
                    >
                      <option value="0">No Thanks</option>
                      <option v-if="extraSettings.isGuaranteedRental" v-bind:value="extraSettings.guaranteedRentalPrice">Yes - {{ extraSettings.guaranteedRentalPrice | prettyCurrency }}</option>
                    </select>
                    <span
                      class="text-xs text-red-600"
                      :class="{
                        '': $v.unitForm.unitGuaranteedRentalPrice.$error,
                        invisible: !$v.unitForm.unitGuaranteedRentalPrice.$error,
                      }"
                    >
                      Required</span
                    >
                  </div>
                </div>

                <div class="w-full flexDir mt-5">
                  <div class="flexDirPurchase w-1/3">
                    <label class="flexDir w-full text-left">Total Purchase Price</label>
                    <span
                      class="lb-admin-form-input-select rounded total-price-container block font-extrabold text-lg text-primary border-none"
                      type="number"
                      disabled="true"
                    >
                      {{ purchasePriceTotal().price | prettyCurrency }}
                    </span>
                  </div>
                </div>
              </form>

              <div class="additionalExtrasBtn">
                <div
                  class="btnPageTwo"
                  v-if="settings.allowReserve || specialBypass"
                >
                  <button
                    disabled
                    class="bg-gray-500 w-1/2 items-center px-4 py-2 text-black text-md font-semibold uppercase tracking-wider cursor-not-allowed rounded"
                    v-if="unit.status === 'reserved'"
                  >
                    Reserved
                  </button>

                  <button
                    disabled
                    class="bg-gray-500 w-1/2 items-center px-4 py-2 text-black text-md font-semibold uppercase tracking-wider cursor-not-allowed rounded"
                    v-if="unit.status === 'sold'"
                  >
                    Sold
                  </button>

                  <button
                    class="bg-primary items-center w-1/2 px-4 py-2 text-black text-md font-semibold uppercase tracking-wider duration-150 hover:bg-primarylight rounded"
                    @click.once="unitFormSubmit"
                    :key="buttonKey"
                    v-if="unit.status === 'pending' || unit.status === 'available'"
                  >
                    Confirm
                  </button>

<!--                  <button-->
<!--                    disabled-->
<!--                    class="bg-gray-400 items-center w-full px-4 py-2 text-white text-md font-semibold tracking-wider duration-150 uppercase hover:bg-gray-500 rounded cursor-not-allowed"-->
<!--                    v-if="unit.status === 'pendingUnitDetails' || unit.status === 'pendingPayment' && unit.status !== 'pending' && unit.modifiedBy != this.clientForm.email"-->
<!--                    @click="setTooltip()"-->
<!--                  >-->
<!--                    Sorry someone has beaten you to this unit.-->
<!--                  </button>-->

<!--                  <button-->
<!--                    v-if="unit.status === 'pendingUnitDetails' || unit.status === 'pendingPayment' && unit.status !== 'pending'"-->
<!--                    class="flex flex-row p-2 mt-2 text-white uppercase tracking-wide bg-secondary rounded centerContainer"-->
<!--                    style="max-width: 220px"-->
<!--                    @click="backToPriceList"-->
<!--                  >-->
<!--                    <BackButtonIcon class="mr-1" />-->
<!--                    Back To Price List-->
<!--                  </button>-->

                  <button
                    class="px-6 py-3 font-semibold uppercase rounded text-center"
                    style="color: #ff0000"
                    @click="cancelReserve"
                  >
                    Cancel Reservation
                  </button>
                </div>
              </div>

              <p class="mt-3 text-sm">
                *Please note you will have <b>{{ formattedTimeLeft }}</b> minutes to complete your reservation.
              </p>
            </div>

            <!-- Payment form -->
            <div
              class="text-center"
              v-bind:class="{
              hidden: openTab !== 3,
              block: openTab === 3,
            }"
            >
              <h2 v-if="settings.allowDeposit" class="w-full">
                Unit {{ unit.name }} is now in progress. Make a payment and the
                unit will be reserved under your details
              </h2>
              <h2 v-else class="w-full">
                Unit {{ unit.name }} is now in progress. The
                unit will be reserved under your details
              </h2>

              <form>
                <input
                  type="hidden"
                  name="merchant_id"
                  :value="paymentForm.merchantID"
                />
                <input
                  type="hidden"
                  name="merchant_key"
                  :value="paymentForm.merchantKey"
                />
                <input type="hidden" name="amount" :value="paymentForm.amount" />
                <input
                  type="hidden"
                  name="item_name"
                  :value="paymentForm.unitName"
                />
                <input
                  type="hidden"
                  name="return_url"
                  :value="paymentForm.returnURL"
                />
                <input
                  type="hidden"
                  name="cancel_url"
                  :value="paymentForm.cancelURL"
                />
                <input
                  type="hidden"
                  name="notify_url"
                  :value="paymentForm.notifyURL"
                />
                <input
                  type="hidden"
                  name="custom_str1"
                  :value="paymentForm.unitID"
                />

                <div class="w-full mt-5">
                  <!--                  <CountDownTimer />-->

                  <div class="base-timer">
                    <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                      <g class="base-timer__circle">
                        <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
                        <path
                          :stroke-dasharray="circleDasharray"
                          class="base-timer__path-remaining"
                          :class="remainingPathColor"
                          d="
                            M 50, 50
                            m -45, 0
                            a 45,45 0 1,0 90,0
                            a 45,45 0 1,0 -90,0
                          "
                        ></path>
                      </g>
                    </svg>
                    <span class="base-timer__label">{{ formattedTimeLeft }}</span>
                  </div>
                  <p v-if="settings.allowDeposit" class="mt-3">Please complete payment within the time limit.</p>

                </div>

                <!--                <input-->
                <!--                  class="mt-10 px-6 py-3 bg-primary font-semibold text-black uppercase rounded cursor-pointer"-->
                <!--                  @click="openTab = 1"-->
                <!--                  type="submit"-->
                <!--                  value="Proceed to Payment"-->
                <!--                />-->
              </form>
<!--              <div-->
<!--                v-if="unit.status === 'pendingPayment' || unit.status === 'reserved' || unit.status === 'sold' && unit.status !== 'pendingUnitDetails' && unit.modifiedBy != this.clientForm.email"-->
<!--                class="flex text-center items-center justify-center"-->
<!--              >-->
<!--                <button-->
<!--                  disabled-->
<!--                  class="mt-10 block px-6 py-3 bg-gray-500 font-semibold text-black uppercase rounded cursor-not-allowed text-center items-center justify-center"-->
<!--                >-->
<!--                  Sorry someone has beaten you to this unit.-->
<!--                </button>-->
<!--              </div>-->
              <div v-if="settings.allowDeposit">
                <div v-if="unit.status === 'pendingUnitDetails' || unit.status === 'pending' || unit.status === 'available'">
                  <paystack
                    :amount="global.depositAmount * 100"
                    :email="clientForm.email"
                    :paystackkey="paystackPublicKey"
                    :reference="`${unit.name.replace(/\s+/g, '_')}_${clientForm.firstName.replace(/\s+/g, '_')}_${clientForm.lastName.replace(/\s+/g, '_')}_${randomToken(5)}`"
                    :callback="processPayment"
                    :close="closePayment"
                    :channels="['bank', 'card', 'qr', 'eft']"
                    currency="ZAR"
                    class="mt-10"
                  >
                    <button
                      class="block px-6 py-3 bg-primary font-semibold text-black uppercase rounded cursor-pointer"
                      @click="pendingPayment"
                    >
                      Proceed to Payment
                    </button>
                  </paystack>
                </div>
              </div>
              <div v-else class="flex text-center items-center justify-center">
                <button
                  class="mt-10 block px-6 py-3 bg-primary font-semibold text-black uppercase rounded cursor-pointer text-center items-center justify-center"
                  @click="noPayment"
                >
                  Proceed to Finish
                </button>
              </div>
              <div class="text-center mt-3">
<!--                <button-->
<!--                  v-if="unit.status === 'pendingPayment' || unit.status === 'reserved' || unit.status === 'sold' || unit.status !== 'pendingUnitDetails'"-->
<!--                  class="px-6 py-3 font-semibold uppercase rounded text-center"-->
<!--                  @click="cancelReserveBackToPriceList"-->
<!--                >-->
<!--                  <router-link-->
<!--                    :to="{ name: 'home' }"-->
<!--                  >-->
<!--                    <BackButtonIcon class="mr-1" />-->
<!--                    Back To Price List-->
<!--                  </router-link>-->
<!--                </button>-->

                <button
                  class="px-6 py-3 font-semibold uppercase rounded text-center"
                  style="color: #ff0000"
                  @click="cancelReserve"
                >
                  Cancel Reservation
                </button>

              </div>
            </div>
          </div>
        </div>

        <!-- Terms and Conditions -->
        <div class="mt-10">
          <h1 class="font-semibold">Terms &amp; Conditions</h1>
          <ul class="mt-2 ml-5 list-disc text-sm">
            <li>
              A unit is only reserved once {{ title }} has received the
              proof of payment of the reservation deposit.
            </li>
            <li>
              The reservation deposit will be deducted from the 10% purchase
              deposit.
            </li>
            <li>
              The balance of the 10% deposit is payable on signature of this
              agreement.
            </li>
            <li>
              The 90% balance of the purchase price outstanding will need to be
              secured by means of a bond or a bank guarantee within 21 days of
              signature of the sale agreement.
            </li>
            <li>
              Completion of this purchase can be made by anyone that is authorised
              by the client.
            </li>
          </ul>
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
import firebase from "firebase";
import { bus } from "@/main";
import { mapGetters } from "vuex";

import UnitPrice from "../../components/app/shared/UnitPrice.vue";

import {
  unitsCollection,
  settingsCollection,
  dealsCollection,
  paystackAuditCollection,
} from "@/firebase";
import axios from "axios";
import paystack from "vue-paystack";

import { required, email, minLength, sameAs } from "vuelidate/lib/validators";

import VuePhoneNumberInput from "vue-phone-number-input";
import isNil from "lodash/isNil";

// import CountDownTimer from "../../components/app/CountDownTimer.vue";

import {
  // APP_TERMS_AND_CONDITIONS_LINK,
  PAYFAST_ACTION_URL,
  PAYFAST_MERCHANT_ID,
  PAYFAST_MERCHANT_KEY,
  // PAYFAST_AMOUNT,
  PAYFAST_RETURN_URL,
  PAYFAST_CANCEL_URL,
  PAYFAST_NOTIFY_URL,
  PENDING_NOTIFY_URL,
  APP_TERMS_AND_CONDITIONS_LINK,
  DELETE_OPTIMISTIC_LOCKS_URL,
  FOO_OPTIMISTIC_LOCKS_URL,
} from "@/Constants";

import BathroomIcon from "vue-material-design-icons/ShowerHead.vue";
import BedroomIcon from "vue-material-design-icons/BedKingOutline.vue";
import CarIcon from "vue-material-design-icons/Car.vue";
import SnowflakeIcon from "vue-material-design-icons/Snowflake.vue";
import ExtAreaIcon from "vue-material-design-icons/FlipToBack.vue";
import IntAreaIcon from "vue-material-design-icons/FlipToFront.vue";
import TotalAreaIcon from "vue-material-design-icons/RulerSquare.vue";

import {
  APP_TITLE,
  PAYSTACK_PUBLIC_KEY,
} from "@/Constants";

import EmailIcon from "vue-material-design-icons/At";
import NameIcon from "vue-material-design-icons/Account";
import HomeIcon from "vue-material-design-icons/HomeCircleOutline";
import BackButtonIcon from "vue-material-design-icons/ArrowLeftCircleOutline";
import ContactIcon from "vue-material-design-icons/FaceAgent.vue";
import ButtonSpinner from "vue-button-spinner";

const FULL_DASH_ARRAY = 283
const WARNING_THRESHOLD = 300
const ALERT_THRESHOLD = 60

const COLOR_CODES = {
  info: {
    color: "green"
  },
  warning: {
    color: "orange",
    threshold: WARNING_THRESHOLD
  },
  alert: {
    color: "red",
    threshold: ALERT_THRESHOLD
  }
};

const TIME_LIMIT = 600

export default {
  name: "ReserveDialog",
  components: {
    UnitPrice,
    // CountDownTimer,
    BackButtonIcon,
    BathroomIcon,
    BedroomIcon,
    CarIcon,
    ExtAreaIcon,
    IntAreaIcon,
    TotalAreaIcon,
    EmailIcon,
    NameIcon,
    VuePhoneNumberInput,
    HomeIcon,
    ContactIcon,
    paystack,
    SnowflakeIcon,
    ButtonSpinner,
  },
  props: {
    units: {
      type: Object,
    },
    // extraSettings: {
    //   parkingOnePrice: null,
    //   parkingOneAmounts: null,
    //   parkingTandemPrice: null,
    //   parkingTandemAmounts: null,
    //   parkingTwoPrice: null,
    //   parkingTwoAmounts: null,
    //   guaranteedRentalName: null,
    //   guaranteedRentalPrice: null,
    //   default: [],
    // },
    // settings: {
    //   allowReserve: false,
    //   allowDiscount: false,
    //   displaySplashScreen: true,
    //   splashScreenMessage: "",
    //   default: [],
    // },
    tableStyle: {
      default: false,
    },
  },
  data() {
    return {
      roleStatuses: ['admin', 'admin-viewonly', 'superadmin'],
      buttonKey: 1,
      isLoading: false,
      status: '',
      extraSettings: {
        id: null,
        parkingTotalBays: null,

        parkingOnePrice: null,
        parkingOneAmounts: null,
        parkingOneName: null,

        parkingTandemPrice: null,
        parkingTandemAmounts: null,
        parkingTandemName: null,

        parkingTwoPrice: null,
        parkingTwoAmounts: null,
        parkingTwoName: null,

        guaranteedRentalName: null,
        guaranteedRentalPrice: null,
      },
      now: new Date(),
      timePassed: 0,
      timerInterval: null,
      globals: [],
      allowBypass: false,
      gotResult: false,
      contactNumber: null,
      unitID: null,
      title: APP_TITLE,
      loading: true,
      paystackPublicKey: PAYSTACK_PUBLIC_KEY,
      tacLink: APP_TERMS_AND_CONDITIONS_LINK,
      openTab: 1,
      settings: {
        allowReserve: false,
        allowDiscount: false,
        displaySplashScreen: true,
        splashScreenMessage: "",
      },
      clientForm: {
        firstName: "",
        lastName: "",
        email: "",
        contact: "",
        e164ContactNumber: "",
        formatNationalContactNumber: "",
        tac: false,
      },
      unit: {
        id: null,
        name: null,
        type: null,
        layout: null,
        price: null,
        discountPrice: null,
        levies: null,
        rates: null,
        guaranteedRental: null,
        unitRentalGuaranteed: null,
        isGuaranteedRental: false,
        twentyYearBondInstalment90: null,
        floor: null,
        bedrooms: null,
        bathrooms: null,
        parking: null,
        isAircon: false,
        aspect: null,
        view: null,
        internalArea: null,
        externalArea: null,
        totalArea: null,
        // customerFullname: null,
        // customerLastname: null,
        // customerContact: null,
        // customerEmail: null,
        // customerID: null,
        // customerAgent: null,
        // thumbnailURL: null,
        // sliderImages: null,
        // displayOnHomePage: null,
        // setGlobalDiscountPrice: null,

        isOneDayOnlyDiscount: false,
        oneDayOnlyDiscount: null,
        oneDayOnlyDiscountDateStart: null,
        oneDayOnlyDiscountDateFinish: null,

        pendingTimestamp: null,
        modified: null,
        modifiedBy: null,

        status: null,
      },
      unitReservedUrl: PAYFAST_NOTIFY_URL,
      unitPendingUrl: PENDING_NOTIFY_URL,

      unitForm: {
        unitParkingBay: "0",
        unitGuaranteedRentalPrice: "0",
      },
      deleteOptimisticLocksUrl: DELETE_OPTIMISTIC_LOCKS_URL,
      fooOptimisticLocksUrl: FOO_OPTIMISTIC_LOCKS_URL,
      parkingStatuses: ['Studio', 'Studio with Patio', 'Studio with Balcony A', 'Studio with Balcony B', 'Studio with Balcony C', 'Ground Floor Studio'],
      isReserver: false
    };
  },
  validations: {
    unitForm: {
      unitParkingBay: { required },
      unitGuaranteedRentalPrice: { required },
    },
    clientForm: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      contact: { required, minLength: minLength(7) },
      e164ContactNumber: { required },
      formatNationalContactNumber: { required },
      tac: {
        sameAs: sameAs(function () {
          return true;
        }),
      },
    },
  },
  methods: {
    onClick () {
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
        this.clear()
      }, 8000)
    },
    getExtraSettings() {
      settingsCollection.doc("extraSettings").onSnapshot((doc) => {
        this.extraSettings.id = doc.id
        this.extraSettings.parkingTotalBays = doc.data().parkingTotalBays || false;

        this.extraSettings.isParkingOne = doc.data().isParkingOne || false;
        this.extraSettings.parkingOnePrice = doc.data().parkingOnePrice || null;
        this.extraSettings.parkingOneAmounts = doc.data().parkingOneAmounts || null;
        this.extraSettings.parkingOneName = doc.data().parkingOneName || null;

        this.extraSettings.isParkingTandem = doc.data().isParkingTandem || false;
        this.extraSettings.parkingTandemPrice = doc.data().parkingTandemPrice || null;
        this.extraSettings.parkingTandemAmounts = doc.data().parkingTandemAmounts || null;
        this.extraSettings.parkingTandemName = doc.data().parkingTandemName || null;

        this.extraSettings.isParkingTwo = doc.data().isParkingTwo || false;
        this.extraSettings.parkingTwoPrice = doc.data().parkingTwoPrice || null;
        this.extraSettings.parkingTwoAmounts = doc.data().parkingTwoAmounts || null;
        this.extraSettings.parkingTwoName = doc.data().parkingTwoName || null;

        this.extraSettings.isGuaranteedRental = doc.data().isGuaranteedRental || false;
        this.extraSettings.guaranteedRentalName = doc.data().guaranteedRentalName || null;
        this.extraSettings.guaranteedRentalPrice = doc.data().guaranteedRentalPrice || null;
      });
    },
    getSettings() {
      settingsCollection.doc("globalSettings").onSnapshot((doc) => {
        this.settings.allowReserve = doc.data().allowReserve;
        this.settings.allowDiscount = doc.data().allowDiscount;
        this.settings.displaySplashScreen = doc.data().displaySplashScreen;
        this.settings.splashScreenMessage = doc.data().splashScreenMessage;
        this.settings.launchDate = doc.data().launchDate ? doc.data().launchDate.toDate() : null;
        this.settings.hidePriceOnSold = doc.data().hidePriceOnSold || false;
        this.settings.setOneDayOnlyDiscount = doc.data().setOneDayOnlyDiscount || false;
        this.settings.allowGlobalDiscount = doc.data().allowGlobalDiscount || false;
        this.settings.globalDiscountAmount = doc.data().globalDiscountAmount || null;
        this.settings.globalDiscountType = doc.data().globalDiscountType || null;
        this.settings.allowDiscountNoSurvey = doc.data().allowDiscountNoSurvey || false;
        this.settings.allowDeposit = doc.data().allowDeposit || false;
        this.settings.isGlobalOverrideAction = doc.data().isGlobalOverrideAction || false;
        this.settings.globalOverrideAction = doc.data().globalOverrideAction || null;
        this.settings.globalOverrideActionButton = doc.data().globalOverrideActionButton || null;
        this.settings.showAvailableUnits = doc.data().showAvailableUnits;
        if (doc.data().showAvailableUnits) {
          this.filterStatus('available-only')
        }
        this.settings.isUsersOnline = doc.data().isUsersOnline;
        this.settings.allowDiscountNoSurvey = doc.data().allowDiscountNoSurvey;
        this.settings.isLaunchDate = doc.data().isLaunchDate || false;
        this.settings.interestRate = doc.data().interestRate;
        this.settings.logo = doc.data().logo;
        this.settings.headerImage = doc.data().headerImage;
        this.settings.depositType = doc.data().depositType || null;
        this.settings.depositAmount = doc.data().depositAmount || null;
        this.settings.bypassAdmin = doc.data().bypassAdmin || false;
      });
    },
    sendUnitPendingEmail() {
      axios
        .post(this.unitPendingUrl, {
          firstName: this.clientForm.firstName,
          lastName: this.clientForm.lastName,
          email: this.clientForm.email,
          e164ContactNumber: this.clientForm.e164ContactNumber,
          unitName: this.unit.name,
        })
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            title: "Unit Pending...",
          });
        })
        .catch((error) => {
          this.$notify({
            group: "global",
            type: "error",
            title: "Error putting unit in pending state.",
            text: error.message,
          });
        });
    },
    setTooltip() {
      this.$emit("update:tooltip", true);
    },
    onTimesUp() {

      const timestamp = firebase.firestore.Timestamp.now();

      unitsCollection
        .doc(this.unit.id)
        .update({
          modified: timestamp,
          modifiedBy: this.user
            ? this.user.profile.email
            : this.clientForm.email,
          status: "available",
        })
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            title: "Unit Reservation Time Out",
          });
          clearInterval(this.timerInterval)
          this.$router.push({ name: "home" });
        });
    },

    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000)
    },
    pendingPayment() {
      unitsCollection
        .doc(this.unit.id)
        .update({
          paymentPaidOn: firebase.firestore.Timestamp.now(),
          paymentStatusBtn: "success",
          modified: firebase.firestore.Timestamp.now(),
          pendingPaymentTimeStamp: firebase.firestore.Timestamp.now(),
          modifiedBy: this.clientForm.email,
          status: "pendingPayment",
          purchasePrice: this.purchasePriceTotal().price,
          price: this.unit.price,
          discountGloblePrice: this.getPrice().price,
        });
    },
    noPayment() {
      const timestamp = firebase.firestore.Timestamp.now();

      // if(this.extraSettings && this.extraSettings.parkingOneAmounts > 0) {
      //   if (this.extraSettings.parkingOnePrice === this.unitForm.unitParkingBay) {
      //     settingsCollection
      //       .doc("extraSettings")
      //       .update({
      //         parkingOneAmounts: this.extraSettings.parkingOneAmounts -= 1,
      //       })
      //   }
      // }
      //
      // if(this.extraSettings && this.extraSettings.parkingTandemAmounts > 0) {
      //   if (this.extraSettings.parkingTandemPrice === this.unitForm.unitParkingBay) {
      //     settingsCollection
      //       .doc("extraSettings")
      //       .update({
      //         parkingTandemAmounts: this.extraSettings.parkingTandemAmounts -= 1,
      //       })
      //   }
      // }
      //
      // if(this.extraSettings && this.extraSettings.parkingTwoAmounts > 0) {
      //   if (this.extraSettings.parkingTwoPrice === this.unitForm.unitParkingBay) {
      //     settingsCollection
      //       .doc("extraSettings")
      //       .update({
      //         parkingTwoAmounts: this.extraSettings.parkingTwoAmounts -= 1,
      //       })
      //   }
      // }
      unitsCollection
        .doc(this.unit.id)
        .update({
          modified: timestamp,
          modifiedBy: this.clientForm.email,
          status: "reserved",
          // statusPayStack: true,
          purchasePrice: this.purchasePriceTotal().price,
          // paymentPaidOn: firebase.firestore.Timestamp.now(),
          // paymentStatusBtn: "success",
          price: this.unit.price,
          customerFirstName: this.clientForm.firstName,
          customerLastName: this.clientForm.lastName,
          customerEmail: this.clientForm.email,
          customerContact: this.clientForm.contact,
        })
        .then(() => {
          let dealsData = {
            unitId: this.unit.id,
            customerFirstName: this.clientForm.firstName,
            customerLastName: this.clientForm.lastName,
            customerEmail: this.clientForm.email,
            customerContact: this.clientForm.contact,
            modified: timestamp,
            modifiedBy: this.clientForm.email,
            unitReservedTime: timestamp,
            status: "reserved",
            statusPayStack: true,
            purchasePrice: this.purchasePriceTotal().price,
            price: this.unit.price,
          };

          dealsCollection
            .orderBy("modified", "desc")
            .where("unitId", "==", this.unit.id)
            .where("status", "==", "pending")
            .limit(1)
            .get()
            .then((deals) => {
              dealsCollection.doc(deals.docs[0].id).update(dealsData);
            });

        })
        .then(() => {
          this.$router.push({ name: "thankyou", params: {unit: this.unit.name } });
        })
        .catch((error) => {
          this.$notify({
            group: "global",
            type: "error",
            title: "Unit Reserved Error",
            text: error.message,
          });
        });


    },
    cancelReserveBackToPriceList() {
      const timestamp = firebase.firestore.Timestamp.now();

      unitsCollection
        .doc(this.unit.id)
        .update({
          modified: timestamp,
          modifiedBy: this.clientForm.email,
          // status: "available",
        })
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            title: "Unit Reservation Return to Price List",
          });
          this.$router.push({ name: "home" });
        });
    },
    backToPriceList() {
      this.$router.push({ name: "home" });
    },
    async cancelReserve() {
      const timestamp = firebase.firestore.Timestamp.now();
      let settingsData = [];

      const response = await firebase.firestore().runTransaction(transaction => {
        return transaction.get(firebase.firestore().collection('settings').doc(this.extraSettings.id)).then(doc => {
          if (this.unit.unitParkingBay === doc.data().parkingOnePrice) {
            console.log('testing settingsData parkingOnePrice', settingsData)
            settingsData = {
              parkingOneAmounts: doc.data().parkingOneAmounts + 1
            }
          } else if (this.unit.unitParkingBay === doc.data().parkingTandemPrice) {
            console.log('testing settingsData parkingTandemPrice', settingsData)
            settingsData = {
              parkingTandemAmounts: doc.data().parkingTandemAmounts + 1
            }
          }
          console.log('testing settingsData', settingsData)

          if (Object.keys(settingsData).length) {
            return transaction
              .update(firebase.firestore().collection('settings').doc(this.extraSettings.id), settingsData);
          }

          return
        })
      })

      console.log(response)

      unitsCollection
        .doc(this.unit.id)
        .update({
          modified: timestamp,
          modifiedBy: this.clientForm.email,
          status: "available",
          unitParkingBay: 0,
        }).then(() => {
        this.$notify({
          group: "global",
          type: "success",
          title: "Unit Reservation Cancelled",
        });
        this.$router.push({ name: "home" });
      })
    },
    processPayment(result) {
      if (result) {
        const timestamp = firebase.firestore.Timestamp.now();
        result.unitName = this.unit.name;
        result.email = this.clientForm.email;
        result.unitId = this.unit.id;
        result.created = timestamp;

        // if(this.extraSettings && this.extraSettings.parkingOneAmounts > 0) {
        //   if (this.extraSettings.parkingOnePrice === this.unitForm.unitParkingBay) {
        //     settingsCollection
        //       .doc("extraSettings")
        //       .update({
        //         parkingOneAmounts: this.extraSettings.parkingOneAmounts -= 1,
        //       })
        //   }
        // }
        //
        // if(this.extraSettings && this.extraSettings.parkingTandemAmounts > 0) {
        //   if (this.extraSettings.parkingTandemPrice === this.unitForm.unitParkingBay) {
        //     settingsCollection
        //       .doc("extraSettings")
        //       .update({
        //         parkingTandemAmounts: this.extraSettings.parkingTandemAmounts -= 1,
        //       })
        //   }
        // }
        //
        // if(this.extraSettings && this.extraSettings.parkingTwoAmounts > 0) {
        //   if (this.extraSettings.parkingTwoPrice === this.unitForm.unitParkingBay) {
        //     settingsCollection
        //       .doc("extraSettings")
        //       .update({
        //         parkingTwoAmounts: this.extraSettings.parkingTwoAmounts -= 1,
        //       })
        //   }
        // }

        if (result.status === "success") {
          unitsCollection
            .doc(this.unit.id)
            .update({
              modified: timestamp,
              modifiedBy: this.clientForm.email,
              status: "reserved",
              statusPayStack: true,
              purchasePrice: this.purchasePriceTotal().price,
              paymentPaidOn: firebase.firestore.Timestamp.now(),
              paymentStatusBtn: "success",
              price: this.unit.price,
              customerFirstName: this.clientForm.firstName,
              customerLastName: this.clientForm.lastName,
              customerEmail: this.clientForm.email,
              customerContact: this.clientForm.contact,
            })
            .then(() => {
              let dealsData = {
                unitId: this.unit.id,
                customerFirstName: this.clientForm.firstName,
                customerLastName: this.clientForm.lastName,
                customerEmail: this.clientForm.email,
                customerContact: this.clientForm.contact,
                modified: timestamp,
                modifiedBy: this.clientForm.email,
                unitReservedTime: timestamp,
                status: "reserved",
                statusPayStack: true,
                purchasePrice: this.purchasePriceTotal().price,
                price: this.unit.price,
              };

              dealsCollection
                .orderBy("modified", "desc")
                .where("unitId", "==", this.unit.id)
                .where("status", "==", "pending")
                .limit(1)
                .get()
                .then((deals) => {
                  dealsCollection.doc(deals.docs[0].id).update(dealsData);
                });

              paystackAuditCollection.doc().set(result);
            })
            .then(() => {
              this.$router.push({ name: "thankyou", params: {unit: this.unit.name } });
            })
            .catch((error) => {
              this.$notify({
                group: "global",
                type: "error",
                title: "Unit Reserved Error",
                text: error.message,
              });
            });
        } else {
          paystackAuditCollection.doc().set(result);

          this.$notify({
            group: "global",
            type: "error",
            title: "Payment Error",
            text: "Please contact support for more info",
          });
        }
      }
    },
    // paymentClosed() {
    // },
    closePayment() {
      unitsCollection
        .doc(this.unit.id)
        .update({
          status: "pendingUnitDetails",
        })
    },
    randomToken(len) {
      let text = "";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text.replace(/\s+/g, "");
    },
    openContactDialog() {
      bus.$emit("update:unit-contact-data", { open: true });
      this.unitDialogData.open = false;
    },
    updatePhoneNumber(e) {
      this.clientForm.e164ContactNumber = e.e164;
      this.clientForm.formatNationalContactNumber = e.formatNational;
    },
    async unitFormSubmit() {
      if (this.unitForm.unitParkingBay > 0) {
        // TODO: Might need to move this to the 'data' section.
        const message = "Someone has beaten you to the punch and there's no parking bays available. Please change your selection.";

        // Transactions can only be run on single documents.
        // The purpose of a transaction is to monitor the "state" of a document, NOT a collection.
        const response = await firebase.firestore().runTransaction(async transaction => {
          return transaction.get(firebase.firestore().collection('settings').doc(this.extraSettings.id)).then(async doc => {
            if (this.unitForm.unitParkingBay === doc.data().parkingOnePrice) { // Single.
              if (doc.data().parkingOneAmounts === null || doc.data().parkingOneAmounts < 1) {
                alert(message)
                return // Returns undefined.
              }
            } else if (this.unitForm.unitParkingBay === doc.data().parkingTandemPrice) { // Tandem.
              if (doc.data().parkingTandemAmounts === null || doc.data().parkingTandemAmounts < 1) {
                alert(message)
                return // Returns undefined.
              }
            }

            // Deduce.
            let settingsData = {}
            if(doc.data().parkingOneAmounts > 0) {
              if (doc.data().parkingOnePrice === this.unitForm.unitParkingBay) {
                settingsData = {
                  parkingOneAmounts: doc.data().parkingOneAmounts -= 1
                }
              }
            }
            if(doc.data().parkingTandemAmounts > 0) {
              if (doc.data().parkingTandemPrice === this.unitForm.unitParkingBay) {
                settingsData = {
                  parkingTandemAmounts: doc.data().parkingTandemAmounts -= 1
                }
              }
            }
            if (Object.keys(settingsData).length) {
              return transaction
                .update(firebase.firestore().collection('settings').doc("extraSettings"), settingsData);
            } else {
              return // Always return something.
            }
          });
        })

        // Abort?
        if (response === undefined || response === null) {
          this.buttonKey++
          return
        }
      }

      // Update the deal.
      await dealsCollection.doc().set({
        unitId: this.unit.id,
        status: "pendingUnitDetails",
        pendingTimestamp: firebase.firestore.Timestamp.now(),
        pendingUnitDetailsTimestamp: firebase.firestore.Timestamp.now(),
        reservationCancelToken: this.cancelToken,
        modified: firebase.firestore.Timestamp.now(),
        purchasePrice: this.purchasePriceTotal().price,
        price: this.unit.price,
        discountType: this.purchasePriceTotal().type,
        discountDiscount: this.purchasePriceTotal().discount,
        customerFirstName: this.clientForm.firstName,
        customerLastName: this.clientForm.lastName,
        customerEmail: this.clientForm.email,
        customerContact: this.clientForm.contact,
      });

      await unitsCollection
        .doc(this.unit.id)
        .update({
          status: "pendingUnitDetails",
          unitParkingBay: this.unitForm.unitParkingBay,
          unitGuaranteedRentalPrice: this.unitForm.unitGuaranteedRentalPrice,
          pendingTimestamp: firebase.firestore.Timestamp.now(),
          pendingUnitDetailsTimestamp: firebase.firestore.Timestamp.now(),
          modified: firebase.firestore.Timestamp.now(),
          purchasePrice: this.purchasePriceTotal().price,
          price: this.unit.price,
          discountType: this.purchasePriceTotal().type,
          discountDiscount: this.purchasePriceTotal().discount,
          customerFirstName: this.clientForm.firstName,
          customerLastName: this.clientForm.lastName,
          customerEmail: this.clientForm.email,
          customerContact: this.clientForm.contact,
        });

      this.openTab = 3;
    },
    async userFormSubmit() {
      this.$v.clientForm.$touch();
      if (this.$v.clientForm.$error) {
        return;
      }

      const response = await firebase.firestore().runTransaction(transaction => {
        return transaction.get(firebase.firestore().collection('units').doc(this.unit.id)).then(doc => {
          if (doc.data().status === "pending") {
            alert("Oh no! Looks like someone beat you to it and secured the unit you are trying to reserve. Please select another unit.")
            this.$router.push({ name: "home" })
            return
          }

          this.isReserver = true
          return transaction
            .update(firebase.firestore().collection('units').doc(this.unit.id), {
              modifiedBy: this.clientForm.email,
              status: "pending",
              pendingTimestamp: firebase.firestore.Timestamp.now(),
              modified: firebase.firestore.Timestamp.now(),
              customerFirstName: this.clientForm.firstName,
              customerLastName: this.clientForm.lastName,
              customerContact: this.clientForm.e164ContactNumber,
              customerEmail: this.clientForm.email,
              // purchasePrice: this.purchasePriceTotal().price, // Why are we doing this?
              // price: this.unit.price, // Why are we doing this?
            });
        });
      })

      console.log(response)

      // Immediately move to next step.
      this.openTab = 2

      dealsCollection.doc().set({
        unitId: this.unit.id,
        customerFirstName: this.clientForm.firstName,
        customerLastName: this.clientForm.lastName,
        customerContact: this.clientForm.e164ContactNumber,
        customerEmail: this.clientForm.email,
        customerAgent: null,
        customerAcceptedTerms: this.clientForm.tac,
        customerAcceptedTermsTimestamp: firebase.firestore.Timestamp.now(),
        customerInitPurchaseTimestamp: firebase.firestore.Timestamp.now(),
        status: "pending",
        pendingTimestamp: firebase.firestore.Timestamp.now(),
        reservationCancelToken: this.cancelToken,
        modified: firebase.firestore.Timestamp.now(),
        purchasePrice: this.purchasePriceTotal().price,
        price: this.unit.price,
      })

      this.sendUnitPendingEmail()
    },
    getUnit(unitName) {
      unitsCollection
        .where("name", "==", unitName)
        .limit(1)
        .onSnapshot((snap) => {
          snap.forEach((doc) => {
            this.gotResult = true;

            this.unit.id = doc.id;
            this.unit.name = doc.data().name;
            this.unit.type = doc.data().type;
            this.unit.layout = doc.data().layout;
            this.unit.price = doc.data().price;
            this.unit.purchasePrice = doc.data().purchasePrice;
            this.unit.discountPrice = doc.data().discountPrice;
            this.unit.levies = doc.data().levies;
            this.unit.rates = doc.data().rates;
            this.unit.twentyYearBondInstalment90 = doc.data().twentyYearBondInstalment90;
            this.unit.floor = doc.data().floor;
            this.unit.bedrooms = doc.data().bedrooms;
            this.unit.bathrooms = doc.data().bathrooms;
            this.unit.parking = doc.data().parking;
            this.unit.isAircon = doc.data().isAircon;
            this.unit.aspect = doc.data().aspect;
            this.unit.view = doc.data().view;
            this.unit.internalArea = doc.data().internalArea;
            this.unit.externalArea = doc.data().externalArea;
            this.unit.totalArea = doc.data().totalArea;
            this.unit.setGlobalDiscountPrice = doc.data().setGlobalDiscountPrice;
            this.unit.status = doc.data().status;
            this.unit.isOneDayOnlyDiscount = doc.data().isOneDayOnlyDiscount;
            this.unit.oneDayOnlyDiscount = doc.data().oneDayOnlyDiscount;
            this.unit.oneDayOnlyDiscountDateStart = doc.data().oneDayOnlyDiscountDateStart;
            this.unit.oneDayOnlyDiscountDateFinish = doc.data().oneDayOnlyDiscountDateFinish;
            this.unit.pendingTimestamp = doc.data().pendingTimestamp;
            this.unit.modified = doc.data().modified;
            this.unit.modifiedBy = doc.data().modifiedBy;
            this.unit.unitParkingBay = doc.data().unitParkingBay;
            this.unit.unitGuaranteedRentalPrice = doc.data().unitGuaranteedRentalPrice;
            this.unit.preReserved = doc.data().preReserved;
          });

          if (!this.gotResult) {
            this.$router.push({ name: "not-found" });
          }

          settingsCollection
            .doc("globalSettings")
            .get()
            .then((doc) => {
              this.settings.allowReserve = doc.data().allowReserve;
              this.settings.allowDiscount = doc.data().allowDiscount;
              this.settings.displaySplashScreen = doc.data().displaySplashScreen;
              this.settings.splashScreenMessage = doc.data().splashScreenMessage;
              this.settings.allowGlobalDiscount =
                doc.data().allowGlobalDiscount || false;
              this.settings.globalDiscountAmount =
                doc.data().globalDiscountAmount || null;
              this.settings.globalDiscountType =
                doc.data().globalDiscountType || null;
            });

          this.loading = false;
        });
    },
    purchasePriceTotal(){
      if (this.settings.allowGlobalDiscount && this.user.loggedIn && this.user.profile.answeredDiscountSurvey && this.settings.allowDiscountNoSurvey !== true) {
        if (this.settings.globalDiscountType === "percentage") {
          return {
            price: parseFloat(this.unit.price) * ((100 - parseFloat(this.settings.globalDiscountAmount)) / 100) + (parseFloat(this.unitForm.unitParkingBay) + parseFloat(this.unitForm.unitGuaranteedRentalPrice)),
            type: "Global",
            discount: ((100 - parseFloat(this.settings.globalDiscountAmount)) / 100)
          };
        }
        if (this.settings.globalDiscountType === "amount") {
          return {
            price: parseFloat(this.unit.price) - parseFloat(this.settings.globalDiscountAmount) + (parseFloat(this.unitForm.unitParkingBay) + parseFloat(this.unitForm.unitGuaranteedRentalPrice)),
            type: "Global",
            discount: parseFloat(this.settings.globalDiscountAmount)
          };
        }
        return {
          price: parseFloat(this.unit.price) + (parseFloat(this.unitForm.unitParkingBay) + parseFloat(this.unitForm.unitGuaranteedRentalPrice)),
          type: "No Discount",
          discount: parseFloat(0)
        };
      }
      else if (this.settings.allowGlobalDiscount && this.settings.allowDiscountNoSurvey) {
        if (this.settings.globalDiscountType === "percentage") {
          return {
            price: parseFloat(this.unit.price) * ((100 - parseFloat(this.settings.globalDiscountAmount)) / 100) + (parseFloat(this.unitForm.unitParkingBay) + parseFloat(this.unitForm.unitGuaranteedRentalPrice)),
            type: "Global",
            discount: ((100 - parseFloat(this.settings.globalDiscountAmount)) / 100)
          };
        }
        if (this.settings.globalDiscountType === "amount") {
          return {
            price: parseFloat(this.unit.price) - parseFloat(this.settings.globalDiscountAmount) + (parseFloat(this.unitForm.unitParkingBay) + parseFloat(this.unitForm.unitGuaranteedRentalPrice)),
            type: "Global",
            discount: parseFloat(this.settings.globalDiscountAmount)
          };
        }
        return {
          price: parseFloat(this.unit.price) + (parseFloat(this.unitForm.unitParkingBay) + parseFloat(this.unitForm.unitGuaranteedRentalPrice)),
          type: "No Discount",
          discount: parseFloat(0)
        };
      }
      return {
        price: parseFloat(this.unit.price) + (parseFloat(this.unitForm.unitParkingBay) + parseFloat(this.unitForm.unitGuaranteedRentalPrice)),
        type: "No Discount",
        discount: parseFloat(0)
      };
    },
    getPrice() {
      if (this.settings.allowGlobalDiscount) {
        if (this.settings.globalDiscountType === "percentage") {
          return {
            price:
              this.unit.price *
              ((100 - this.settings.globalDiscountAmount) / 100),
            type: "Global",
            discount: ((100 - parseFloat(this.settings.globalDiscountAmount)) / 100)
          };
        }
        if (this.settings.globalDiscountType === "amount") {
          return {
            price: this.unit.price - this.settings.globalDiscountAmount,
            type: "Global",
            discount: parseFloat(this.settings.globalDiscountAmount)
          };
        }
        return {
          price: this.unit.price,
          type: "No Discount",
          discount: parseFloat(0)
        };
      }

      if (this.unit.setGlobalDiscountPrice) {
        if (this.unit.discountPrice) {
          return {
            price: this.unit.discountPrice,
            type: "Unit Specific",
          };
        }
      }
      if (this.settings.allowDiscount) {
        if (this.unit.discountPrice) {
          return {
            price: this.unit.discountPrice,
            type: "Global Unit Specific",
          };
        }
      }
      return this.unit.price;
    },
    agentBypass() {
      const timestamp = firebase.firestore.Timestamp.now();

      unitsCollection
        .doc(this.unit.id)
        .update({
          customerFirstName: this.clientForm.firstName,
          customerLastName: this.clientForm.lastName,
          customerEmail: this.clientForm.email,
          customerContact: this.clientForm.contact,
          customerAgent: this.user.profile.email,
          modified: timestamp,
          modifiedBy: this.user.profile.email,
          status: "reserved",
          discountType: this.getPrice().type,
          discountDiscount: this.getPrice().discount,
          purchasePrice: this.purchasePriceTotal().price,
          price: this.unit.price,
        })
        .then(() => {
          let dealsData = {
            unitId: this.unit.id,
            customerFirstName: this.clientForm.firstName,
            customerLastName: this.clientForm.lastName,
            customerEmail: this.clientForm.email,
            customerContact: this.clientForm.contact,
            customerAgent: this.user.profile.email,
            modified: timestamp,
            modifiedBy: this.user.profile.email,
            status: "reserved",
            price: this.unit.price,
            purchasePrice: this.purchasePriceTotal().price,
            originalPrice: this.unit.price,
            discountType: this.getPrice().type,
            discountDiscount: this.getPrice().discount,
          };
          dealsCollection.doc().set(dealsData);
        })
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            title: "Unit Reserved",
          });
          this.$router.replace({ name: "home" });
        })
        .catch((error) => {
          this.$notify({
            group: "global",
            type: "error",
            title: "Unit Reserved Error",
            text: error.message,
          });
        });
    },
  },
  created() {
    this.getExtraSettings();
    this.getSettings();

    settingsCollection.orderBy("globalDiscountAmount").onSnapshot((snap) => {
      let globals = [];

      snap.forEach((doc) => {
        globals.push({
          allowReserve: doc.data().allowReserve,
          allowDiscount: doc.data().allowDiscount,
          displaySplashScreen: doc.data().displaySplashScreen,
          allowGlobalDiscount: (doc.data().allowGlobalDiscount || false),
          globalDiscountType: (doc.data().globalDiscountType || null),
          globalDiscountAmount: (doc.data().globalDiscountAmount || null),
          interestRate: (doc.data().interestRate || null),
          splashScreenMessage: doc.data().splashScreenMessage,
          launchDate: (doc.data().launchDate ? doc.data().launchDate.toDate() : null),
          logo: doc.data().logo,
          headerImage: doc.data().headerImage,
          hidePriceOnSold: (doc.data().hidePriceOnSold || false),
          allowDeposit: (doc.data().allowDeposit || false),
          depositAmount: (doc.data().depositAmount || null),
          setOneDayOnlyDiscount: (doc.data().setOneDayOnlyDiscount || false),
          isGlobalOverrideAction: (doc.data().isGlobalOverrideAction || false),
          globalOverrideAction: (doc.data().globalOverrideAction || null),
          globalOverrideActionButton: (doc.data().globalOverrideActionButton || null),
          showAvailableUnits: doc.data().showAvailableUnits,
          isUsersOnline: doc.data().isUsersOnline,
          allowDiscountNoSurvey: doc.data().allowDiscountNoSurvey,
          isLaunchDate: (doc.data().isLaunchDate || false),
          depositType: (doc.data().depositType || null),
          bypassAdmin: (doc.data().bypassAdmin || false),
        });
      });
      this.globals = globals;
    });
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    disableReserve: function () {
      if (this.settings && this.settings.launchDate && this.settings.launchDate > this.now) {
        if (this.unit.isOneDayOnlyDiscount === true) {
          return false;
        }
        if (this.roleStatuses.includes(this.user.profile.role) && this.globals[0].bypassAdmin === true) {
          return false;
        }
        return true;
      }
      return false;
    },
    unitId() {
      return this.$route.params.id
    },
    circleDasharray() {
      return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`
    },
    formattedTimeLeft() {
      const timeLeft = this.timeLeft
      const minutes = Math.floor(timeLeft / 60)
      let seconds = timeLeft % 60

      if (seconds < 10) {
        seconds = `0${seconds}`
      }

      return `${minutes}:${seconds}`
    },

    timeLeft() {
      return TIME_LIMIT - this.timePassed
    },

    unitStatus() {
      console.log('asfsfdsaafd')
      return this.unit.status
    },

    timeFraction() {
      const rawTimeFraction = this.timeLeft / TIME_LIMIT
      return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction)
    },

    remainingPathColor() {
      const { alert, warning, info } = COLOR_CODES

      if (this.timeLeft <= alert.threshold) {
        return alert.color
      } else if (this.timeLeft <= warning.threshold) {
        return warning.color
      } else {
        return info.color
      }
    },
    globalDiscountedPrice() {
      if (this.settings.allowGlobalDiscount && this.unit && this.unit.price) {
        if (this.settings.globalDiscountType === "percentage") {
          return (
            this.unit.price * ((100 - this.settings.globalDiscountAmount) / 100)
          );
        }
        return this.unit.price - this.settings.globalDiscountAmount;
      }
      return 0;
    },
    specialBypass: function () {
      return false;
    },
    paymentForm: function () {
      const cancelURL = `${PAYFAST_CANCEL_URL}/${this.unit.id}?token=${this.cancelToken}`;
      console.log(cancelURL);

      let paymentData = {
        actionURL: PAYFAST_ACTION_URL,
        merchantID: PAYFAST_MERCHANT_ID,
        merchantKey: PAYFAST_MERCHANT_KEY,
        // amount: PAYFAST_AMOUNT,
        amount: this.globals.depositAmount,
        unitName: this.unit.name,
        returnURL: PAYFAST_RETURN_URL,
        cancelURL: cancelURL,
        notifyURL: PAYFAST_NOTIFY_URL,
        unitID: this.unit.id,
      };

      return paymentData;
    },
    cancelToken: function () {
      return this.randomToken(10);
    },
  },
  mounted() {
    this.startTimer()

    const unitName = this.$route.params.name;
    const user = this.user;

    if (!isNil(unitName)) {
      this.getUnit(unitName);
    }

    if (!isNil(user) && user.loggedIn) {
      if (!isNil(user) && !isNil(user.profile)) {
        if (this.user.profile.role !== "agent") {
          this.clientForm.firstName = this.user.profile.firstName;
          this.clientForm.lastName = this.user.profile.lastName;
          this.clientForm.email = this.user.profile.email;
          this.clientForm.contact = this.user.profile.formatNationalContactNumber;
          this.clientForm.e164ContactNumber = this.user.profile.e164ContactNumber;
          this.clientForm.formatNationalContactNumber = this.user.profile.formatNationalContactNumber;
        }

        if (this.user.profile.role === "agent") {
          this.allowBypass = true;
        }
      } else {
        this.$router.replace({ name: "home" });
      }
    }
  },
  watch: {
    unitStatus(newValue) {
      if (newValue === 'pending' && !this.isReserver) {
        alert("Oh no! Looks like someone beat you to it and secured the unit you are trying to reserve. Please select another unit.")
        this.$router.push({ name: "home" })
      }
    },
    timeLeft(newValue) {
      if (newValue === 0) {
        this.onTimesUp()

        unitsCollection
          .doc(this.unitId)
          .update({
            reserveInProgress: false,
            status: 'available'
          })
          .then(() => {
            this.$router.push({ name: 'home'})
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
  },
};
</script>

<style>
.centerContainer {
  justify-content: center;
  align-items: center;
}

.base-timer {
  position: relative;
  margin: auto;
  width: 200px;
  height: 200px;
}

.base-timer__svg {
  transform: scaleX(-1);
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: grey;
}

.base-timer__path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__path-remaining.green {
  color: #41b883;
}

.base-timer__path-remaining.orange {
  color: orange;
}

.base-timer__path-remaining.red {
  color: red;
}

.base-timer__label {
  position: absolute;
  width: 200px;
  height: 200px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
}

.total-price-container {
  background: lightgray;
}

.flexDir {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flexDirPurchase {
  width: 33.333333%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.tooltip .tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 9999;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.formInput {
  display: flex;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.formInputField {
  width: 50%;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.additionalExtras {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1.25rem;
}

.additionalExtrasBtn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1.25rem;
}

.btnPageTwo {
  width: 50%;
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.modalContainer {
  margin-top: 0.75rem;
  padding: 1.25rem;
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  border-radius: 0.5rem;
  min-width: 650px;
}

@media only screen and (max-width: 595px) {
  .modalContainer {
    min-width: 560px;
  }
}

@media only screen and (max-width: 425px) {
  .modalContainer {
    min-width: 0;
  }

  .formInput {
    flex-direction: column;
  }

  .formInputField {
    width: 100%;
  }

  .flexDirPurchase {
    width: 100%;
  }

  .additionalExtras {
    flex-direction: column;
  }

  .additionalExtrasBtn {
    flex-direction: column;
  }
  .btnPageTwo {
    width: 100%;
  }
}
</style>
